import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Hero from "../../modules/hero/hero";
import RenderModules from "../../lib/renders/renderModules";
import "./ToggleButton.scss";
import Chevron from "../../images/Chevron.inline.svg";

const IndexPage = ({ data, location }) => {
  const { hero, modules, toggleButton } = data?.datoCmsPage || {};
  const [currentToggle, setCurrentToggle] = useState(null);

  useEffect(() => {
    // Reset the toggle when the locale changes
    if (toggleButton && toggleButton.length > 1) {
      setCurrentToggle(toggleButton[0].toggleid);
    } else {
      setCurrentToggle(null);
    }
  }, [data]);

  const handleToggle = (id) => {
    setCurrentToggle(id);
  };

  // Filter modules based on currentToggle if toggleButton is present
  const filteredModules = currentToggle
    ? modules.filter((module) => module.toggleId === currentToggle)
    : modules;

  const renderToggleButtons = () => {
    if (!toggleButton || toggleButton.length <= 1) return null;

    return toggleButton.map((button) => (
      <button
        key={button.toggleid}
        onClick={() => handleToggle(button.toggleid)}
        className={`btn ${button.toggleid === currentToggle ? "active" : ""}`}
      >
        {button.toggleButtonName}
        <Chevron />
      </button>
    ));
  };

  return (
    <>
      {hero && hero[0] && <Hero data={hero[0]} location={location} />}

      {/* Render toggle buttons if there are more than one */}
      {toggleButton && toggleButton.length > 1 && (
        <div className="button-container">{renderToggleButtons()}</div>
      )}

      {/* Render modules based on filteredModules */}
      {filteredModules && <RenderModules data={filteredModules} />}
    </>
  );
};

export default IndexPage;





export const pageQuery = graphql`
query PageQuery($id : String, $locale : String! ) {
  datoCmsPage(originalId : {eq : $id}, locale : {eq: $locale}) {
    customSchema
    footerText
    hero{
      ...HeroFragment
    }
    modules{
      __typename
      ...ModuleHorizontalImageContentGridFragment
      ...ModuleFormFragment
      ...ModuleCTASideBySideFragment
      ...ModuleCTAOverlayFragment
      ...ModuleLinkDropdownFragment
      ...ModuleLogosFragment
      ...ModuleColumnFragment
      ...ModuleGridFragment
      ...ModuleParallaxFragment
      ...ModuleSliderFragment
      ...ModuleSliderNoTabFragment
      ...ModuleStatsModuleFragment
      ...ModuleTextContentFragment
      ...ModuleTilesFragment
      ...ModuleJsonAnimateFragment
    }
    toggleButton {
      toggleButtonName
      toggleid
    }
    locale
    passwordProtected{
      backgroundImage{
        ...BackgroundImageFragment
      }
      copy
      password
      title
    }
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    title
  }
}
`



