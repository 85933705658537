import React, {lazy, Suspense}  from 'react';
import Render from './render';

const ColumnContent = lazy(() => import('../../modules/columnContent/columnContent'));
const Cta = lazy(() => import("../../modules/cta/cta"));
const Form = lazy(() => import("../../modules/form/form"));
const Grid = lazy(() => import('../../modules/grid/grid'));
const LinkDropDownBar = lazy(() => import('../../modules/linkDropdownBar/linkDropdownBar'));
const Logos = lazy(() => import('../../modules/logos/logos'));
const Media = lazy(() => import('../../components/mediaMobileDesktop/mediaMobileDesktop'));
const Parallax = lazy(() => import('../../modules/parallax/parallax'));
const Slider = lazy(() => import('../../modules/slider/slider'));
const Stats = lazy(() => import('../../modules/stats/stats'));
const TextContent = lazy(() => import('../../modules/textContent/textContent'));
const Tiles = lazy(() => import('../../modules/tiles/tiles'));
const JsonAnimate = lazy(() => import('../../modules/jsonanimate/jsonanimate'));

/**
 * 
 * 
 * @category Renders
 * @summary Renders page builder modules
 * @return {array} Returns the correct modules
 * 
 */
const RenderModules = ({data, ...other}) => {
  const map = {
    'DatoCmsComponentMobileDesktopVersion' : Media,
    'DatoCmsModuleLinkDropdownBar' : LinkDropDownBar,
    'DatoCmsModuleColumnContent':ColumnContent,
    'DatoCmsModuleCtaOverlay' : Cta,
    'DatoCmsModuleCtaSideBySide' : Cta,
    'DatoCmsModuleForm' : Form,
    'DatoCmsModuleGrid' : Grid,
    'DatoCmsModuleHorizontalImageContentGrid' : Grid,
    'DatoCmsModuleLogo' : Logos,
    'DatoCmsModuleParallax': Parallax,
    'DatoCmsModuleSlider' : Slider,
    'DatoCmsModuleSliderNoTab' : Slider,
    'DatoCmsModuleStat': Stats,
    'DatoCmsModuleTextContent' : TextContent,
    'DatoCmsModuleTile' : Tiles,
    'DatoCmsModuleJsonAnimate' : JsonAnimate    
  };
  return (
    <Suspense fallback="">
      <Render data={data} map={map} {...other} />
    </Suspense>
  ) 
};

export default RenderModules;


